import React, { useCallback, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Text from '../common/Text';
import colors from '../common/colors';

type StyleProps = {
  color: string;
};

const Input = styled.input<StyleProps>`
  width: 0px;
  height: 0px;
  margin-bottom: 20px;
  margin-left: 10px;

  :before {
    visibility: visible;
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.lightGrey};
    background: ${colors.snow};
  }

  :checked:before {
    background: radial-gradient(${props => props.color} 0%, ${props => props.color} 40%, transparent 50%, transparent);
    border-color: ${props => props.color};
  }
`;

const Container = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  position: relative;
`;

const CarrierInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 23px;
`;

const LogoImage = styled.img`
  max-height: 30px;
  max-width: 30px;
  margin-right: 8px;
`;

const Label = styled.label<StyleProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

type Props = {
  id: string;
  title: string;
  logoImageUrl?: string;
  checked?: boolean;
  onClick: (value: string) => void;
};

function CarrierOption(props: Props) {
  const { id, title, logoImageUrl, checked, onClick } = props;
  const { t } = useTranslation('carrier-services');

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onClick(e.target.value);
    },
    [onClick]
  );

  return (
    <Container color={colors.blue}>
      <Label htmlFor={id} color={colors.blue}>
        <Input
          name="carrierSelect"
          type="radio"
          value={id}
          id={id}
          checked={checked}
          onChange={onChangeHandler}
          aria-checked={checked}
          aria-labelledby={`label-${id}`}
          color={colors.blue}
        />
        <CarrierInfo id={`label-${id}`}>
          <LogoImage src={logoImageUrl} alt="" />
          <Text>{t(id, { defaultValue: title })}</Text>
        </CarrierInfo>
      </Label>
    </Container>
  );
}

export default React.memo(CarrierOption);
